/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


import 'bootstrap/dist/css/bootstrap.min.css';
import 'jarallax/dist/jarallax.css';

import "./src/assets/css/font-awesome.min.css"
import "./src/assets/css/themify-icons.css"
import "./src/assets/css/et-line.css"
import "./src/assets/css/owl.carousel.css"
import "./src/assets/css/vertical.min.css"
import "./src/assets/css/animate.css"
import "./src/assets/css/template.css"
import "./src/assets/css/template-concrete.css"
import "./src/assets/css/custom.css"


