// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gallery-topic-js": () => import("../src/templates/galleryTopic.js" /* webpackChunkName: "component---src-templates-gallery-topic-js" */),
  "component---src-templates-gallery-js": () => import("../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merci-js": () => import("../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-js": () => import("../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-reseaux-sociaux-js": () => import("../src/pages/reseaux-sociaux.js" /* webpackChunkName: "component---src-pages-reseaux-sociaux-js" */)
}

